.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: start;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    background: linear-gradient(to bottom, #e3f2fd, #bbdefb);
    animation: gradient 60s ease infinite;
    padding: 20px;
}

.forgot-password-form {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 30px;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    margin-top: 100px;
}

.forgot-password-form h2 {
    margin-bottom: 25px;
    text-align: center;
    color: #007bff;
    font-size: 24px;
}

.forgot-password-form .form-label {
    font-weight: bold;
    color: #333;
}

.forgot-password-form .form-control {
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: none;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.forgot-password-form .form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(78, 84, 200, 0.5);
}

.forgot-password-form .btn-primary {
    width: 100%;
    margin-top: 20px;
    background-color: #007bff;
    border: none;
    padding: 10px 0;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.3s;
}

.forgot-password-form .btn-primary:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.forgot-password-form .btn-secondary {
    width: 100%;
    margin-top: 20px;
    background-color: #6c757d;
    border: none;
    padding: 10px 0;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.3s;
}

.forgot-password-form .btn-secondary:hover {
    background-color: #5a6268;
    transform: translateY(-2px);
}

.forgot-password-form .alert {
    margin-top: 20px;
}

.form-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
