@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:wght@300;400;500;600;700&display=swap');
.search-table-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    min-height: 80vh;
    background: #90CCF4;
    /*background: linear-gradient(to bottom, #e3f2fd, #bbdefb);*/
    background-size: 200% 200%;
    animation: gradient 60s ease infinite;
    font-family: 'IBM Plex Sans Condensed', sans-serif;
}

.search-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 16px;
}

.table-container {
    /*margin: 20px auto;*/
    max-width: 900px;
}

.search-button {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background-color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.search-icon {
    margin-right: 8px;
    font-size: 18px;
}

.search-text {
    font-size: 14px;
}

.search-bar {
    margin-left: 16px;
}

.react-select__control {
    width: 250px;
}

.react-select__menu {
    width: 250px;
}

.tags-cell {
    display: flex;
    flex-wrap: nowrap;
    gap: 4px;
    max-width: 150px;
    overflow: hidden;
    position: relative;
}

.added-tag {
    padding: 2px 6px;
    border-radius: 12px;
    font-size: 12px;
    color: white;
    white-space: nowrap;
    flex-shrink: 0;
}

.tags-cell::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 30px;
    background: linear-gradient(to right, transparent, white);
    pointer-events: none;
    display: none;
}

.tags-cell.overflowing::after {
    display: block;
}

.ellipsis {
    position: absolute;
    right: 0;
    bottom: 0px;
    background: white;
    padding: 0 4px;
    display: none;
}

.tags-cell.overflowing .ellipsis {
    display: block;
}

.tag-0 {
    background-color: #f0932b;
}

.tag-1 {
    background-color: #6ab04c;
}

.tag-2 {
    background-color: #4834d4;
}

.tag-3 {
    background-color: #eb4d4b;
}

.added-tag:nth-child(odd) {
    background-color: #007bff;
}

.added-tag:nth-child(even) {
    background-color: #28a745;
}

.added-tag:nth-child(3n) {
    background-color: #f0932b;
}

.added-tag:nth-child(4n) {
    background-color: #eb4d4b;
}

.action-icons {
    display: flex;
    gap: 8px;
}

.action-icon {
    cursor: pointer;
    font-size: 24px;
}

.action-star {
    color: gold;
}


@media screen and (max-width: 768px) {
    .tags-cell {
        max-width: 100px;
    }

    .added-tag {
        font-size: 10px;
        padding: 1px 4px;
    }
    .table-container {
        /*margin: 20px auto;*/
        max-width: 400px;
    }
}