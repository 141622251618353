@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:wght@300;400;500;600;700&display=swap');

:root {
    --primary-color: #4a90e2;
    --secondary-color: #50c878;
    --accent-color: #ff6b6b;
    --background-color: #f8f9fa;
    --text-color: #333333;
    --card-gradient: linear-gradient(135deg, #4a90e2, #50c878);
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'IBM Plex Sans Condensed', sans-serif;
    background: #ECECEC;
}

.intro-section {
    padding: 50px 20px;
    background: #5DA2D5;
    text-align: center;
    color: black;
    position: relative;
    overflow: hidden;
    border-right: 1px solid #e0e0e0;
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);
}

.intro-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"%3E%3Cpath fill="%23ffffff" fill-opacity="0.1" d="M0,96L48,112C96,128,192,160,288,186.7C384,213,480,235,576,213.3C672,192,768,128,864,122.7C960,117,1056,171,1152,197.3C1248,224,1344,224,1392,224L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"%3E%3C/path%3E%3C/svg%3E') no-repeat bottom;
    background-size: cover;
}

.intro-section h1 {
    font-size: 36px;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
    color: black;
}

.intro-section p {
    font-size: 20px;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    color: black;
}

.intro-section .buttons {
    position: relative;
    z-index: 1;
}

.intro-section .buttons .start-now-btn,
.intro-section .buttons .architecture-btn {
    background-color: white;
    color: var(--primary-color);
    border: none;
    padding: 10px 18px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    margin: 10px;
    border-radius: 30px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.intro-section .buttons .start-now-btn:hover,
.intro-section .buttons .architecture-btn:hover {
    background-color: #4a90e2;
    color: white;
    transform: translateY(-3px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.stackable-cards-section {
    display: flex;
    height: 100vh;
    overflow: visible;
}

.left-content {
    flex: 2;
    padding: 30px;
    background: #ECECEC;
    color: var(--text-color);
    overflow: hidden;
    position: sticky;
    top: 0;
    max-width: 100%;
    border-right: 1px solid #e0e0e0;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
}

.left-content::-webkit-scrollbar {
    width: 2px;
}

.left-content::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 4px;
}

.left-content h1,
.left-content h2 {
    font-family: 'IBM Plex Sans Condensed', sans-serif;
    margin-bottom: 20px;
}

.left-content h1 {
    font-size: 32px;
    margin-top: 0;
    /*color: var(--primary-color);*/
}

.left-content h2 {
    font-size: 28px;
    color: black;
    border-bottom: 2px solid var(--accent-color);
    padding-bottom: 10px;
    margin-top: 30px;
    display: flex;
    gap: 10px;
    align-items: start;
    justify-content: start;
}

.left-content h2 span {
    flex-grow: 1;
    text-align: center;
}

.left-content p {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 18px;
    line-height: 1.8;
    margin-bottom: 20px;
}

.left-content ul {
    list-style-type: none;
    padding-left: 20px;
}

.left-content ul li {
    font-size: 18px;
    line-height: 1.8;
    margin-bottom: 10px;
    position: relative;
}

.left-content ul li::before {
    content: '•';
    color: var(--accent-color);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.feature {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.feature:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.feature h2 {
    margin-top: 10px;
}

.feature-icon {
    color: var(--primary-color);
    margin-right: 20px;
}

.cta {
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.cta h2 {
    margin-top: 10px;
    align-items: center;
    justify-content: center;
}

.cta:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.right-content {
    flex: 2;
    position: relative;
    overflow: visible;
    z-index: 2;
    height: 100vh;
}
.right-content h2 {
    margin-top: 20px;
    font-size: 32px;
    margin-left: 0px;
    color: black;
}

.cards {
    position: relative;
    width: 100%;
    height: 100%;
}

.card {
    padding: 1.5em;
    color: black;
    width: 80%;
    height: 200px;
    /*background: var(--card-gradient);*/
    font-size: 18px;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    top: 20px;
    left: 10%;
    will-change: transform;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.card-heading {
    background: transparent;
    border: 1px solid white;
    color: black;
    font-size: 22px;
    padding: 6px 8px;
    margin-bottom: 10px;
    cursor: default;
    pointer-events: none;
    position: absolute;
    top: 15px;
    left: 15px;
    border-radius: 5px;
}

.card ul {
    margin-top: 50px;
}

.card li {
    margin-bottom: 5px;
}
.card span {
    margin-top: 50px;
}

/*.card li {*/
/*    margin-bottom: 5px;*/
/*}*/

.card h2 {
    font-size: 24px;
    margin-bottom: 0.5em;
}

.architecture-section {
    padding: 30px 20px;
    background-color: #f0f8ff;
    text-align: center;
    color: #333;
    position: relative;
    overflow: hidden;
}

.architecture-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, rgba(74, 144, 226, 0.1) 0%, rgba(80, 200, 120, 0.05) 100%);
    opacity: 0.5;
}

.architecture-header {
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
}

.architecture-header h2 {
    font-size: 34px;
    margin-top: 20px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-icon {
    margin-right: 15px;
    font-size: 2.5rem;
    color: var(--primary-color);
}

.architecture-header p {
    font-size: 1.2rem;
    color: #555;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
}

.architecture-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.architecture-image-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto 60px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    overflow: hidden;
    transition: transform 0.3s ease;
    background-color: #ffffff;
    position: relative;
    padding: 20px;
}

.architecture-image-container:hover {
    transform: translateY(-10px);
}

.architecture-image-wrapper {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;
    border-radius: 10px;

}

.architecture-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    /*background-color: #4a90e2;*/
}

.architecture-image-wrapper img:not([src=""]):not([src="#"]) ~ .architecture-image-wrapper::before {
    display: none;
}

.architecture-details {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    width: 100%;
    max-width: 1200px;
}

.architecture-card {
    background: #ffffff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.architecture-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}

.arch-icon {
    font-size: 3rem;
    color: var(--secondary-color);
    margin-bottom: 20px;
}

.architecture-card h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
}

.architecture-card p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
}

@media screen and (max-width: 1024px) {
    .stackable-cards-section {
        flex-direction: column;
        height: auto;
    }

    .left-content, .right-content {
        flex: 1;
        width: 100%;
        height: auto;
    }

    .left-content {
        position: static;
        border-right: none;
        border-bottom: 1px solid #ddd;
    }

    .card {
        position: static;
        width: 90%;
        margin: 10px auto;
    }

    .architecture-card {
        flex: 1 1 calc(50% - 30px);
    }
}

@media screen and (max-width: 768px) {
    .intro-section h1 {
        font-size: 36px;
    }

    .intro-section p {
        font-size: 18px;
    }

    .left-content h1 {
        font-size: 30px;
    }

    .left-content h2 {
        font-size: 24px;
    }

    .left-content p, .left-content ul li {
        font-size: 16px;
    }

    .card-heading {
        font-size: 20px;
    }

    .architecture-section h2 {
        font-size: 30px;
    }

    .architecture-section p {
        font-size: 18px;
    }

    .architecture-card {
        flex: 1 1 100%;
    }
}

@media screen and (max-width: 480px) {
    .intro-section .buttons .start-now-btn,
    .intro-section .buttons .architecture-btn {
        width: 100%;
        margin: 10px 0;
    }

    .card {
        height: auto;
        min-height: 200px;
    }

    .architecture-image {
        max-width: 100%;
    }
}

.bg-yellow {
    background: #F3D250;
}

.bg-blue {
    background: #90CCF4;
}

.bg-red {
    background: #F78888;
}