@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:wght@300;400;500;600;700&display=swap');
.signup-container {
    display: flex;
    justify-content: center;
    align-items: start;
    min-height: 100vh;
    background: linear-gradient(to bottom, #e3f2fd, #bbdefb);
    padding: 20px;
    font-family: 'IBM Plex Sans Condensed', sans-serif;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #7c4dff;
    font-size: 28px;
}

.signup-form {
    margin-top: 50px;
}

.signup-form,
.verification-options,
.otp-form,
.email-toast {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 30px;
    border-radius: 10px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.email-toast {
    margin-top: 50px;
}

.signup-form .form-label {
    font-weight: bold;
}

.signup-form .form-control {
    border-radius: 5px;
}

.signup-form .btn-primary {
    width: 100%;
    margin-top: 20px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.signup-form .btn-primary:hover {
    background-color: #5a33d6;
    border-color: #5a33d6;
}

.signup-form .alert {
    margin-top: 20px;
}

.signup-form .text-center {
    margin-top: 20px;
    font-size: 14px;
}

.signup-form .text-center a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.signup-form .text-center a:hover {
    text-decoration: underline;
}

.password-input {
    position: relative;
}

.password-toggle {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

.password-toggle svg {
    width: 20px;
    height: 20px;
    fill: #6c757d;
}

.password-toggle:hover svg {
    fill: #000;
}

.verification-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
}

.verification-options .btn {
    width: 100%;
    margin-bottom: 10px;
    background-color: #7c4dff;
    border-color: #7c4dff;
}

.verification-options .btn:hover {
    background-color: #5a33d6;
    border-color: #5a33d6;
}

.verification-options .divider-text {
    margin: 10px 0;
    text-align: center;
    color: #6c757d;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
