.add-job-application-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    background-size: 200% 200%;
    background: #90CCF4;
    /*background: linear-gradient(to bottom, #e3f2fd, #bbdefb);*/
    animation: gradient 60s ease infinite;
    padding: 20px;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.add-job-application {
    max-width: 600px;
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-job-application h2 {
    margin-bottom: 20px;
    text-align: center;
    color: #007bff;
    font-size: 24px;
}

.form-star {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.form-star label {
    display: block;
    margin-right: 10px;
    font-weight: bold;
    color: #333;
    font-size: 16px;
}

.action-icon {
    cursor: pointer;
    font-size: 24px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
    font-size: 16px;
}

.form-group input[type='text'],
.form-group input[type='date'],
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 8px;
    border: 2px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    transition: border-color 0.3s;
}

.form-group input[type='text']:focus,
.form-group input[type='date']:focus,
.form-group select:focus,
.form-group textarea:focus {
    border-color: #007bff;
    outline: none;
}

.form-group input[type='checkbox'] {
    margin-left: 5px;
}

.form-group .error {
    border-color: #ff0000;
}

.form-group .error-message {
    color: #ff5252;
    font-size: 12px;
    margin-top: 5px;
}

.form-group textarea {
    height: 80px;
}

.form-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.form-row button {
    flex: 1;
    padding: 10px;
}

.btn-secondary {
    background-color: #6c757d;
    color: white;
    font-weight: bold;
}

.btn-secondary:hover {
    background-color: #5a6268;
    transform: translateY(-2px);
}

.btn-primary {
    border-radius: 6px;
    background-color: #007bff;
    color: white;
    font-weight: bold;
}

.btn-primary:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.action-star {
    color: #ffd700;
}

.date-picker-wrapper {
    position: relative;
}

.rdp {
    position: absolute;
    z-index: 1000;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
}

.rdp-months {
    padding: 16px;
}

.rdp-month {
    background: white;
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
    background-color: #007bff;
    color: white;
}

.rdp-day:hover:not(.rdp-day_selected) {
    background-color: #f0e6ff;
}

.rdp-head_cell {
    font-weight: 600;
    color: #666;
}

.rdp-nav_button:hover {
    background-color: #f0e6ff;
}

.date-display {
    display: flex;
    align-items: center;
    padding: 8px;
    border: 2px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    transition: all 0.3s;
    cursor: pointer;
    background-color: white;
}

.date-display:hover {
    border-color: #007bff;
}
