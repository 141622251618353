.verification-container {
    display: flex;
    justify-content: center;
    align-items: start;
    min-height: 100vh;
    padding: 20px;
    background: linear-gradient(to bottom, #e3f2fd, #bbdefb);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.verification-message {
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    text-align: center;
    max-width: 400px;
    width: 100%;
    margin-top: 80px;
    background-color: rgba(255, 255, 255, 0.95);
    transition: all 0.3s ease;
}

.verification-message h2 {
    font-size: 28px;
    margin-bottom: 20px;
    font-weight: 600;
    color: #333;
}

.verification-message p {
    font-size: 16px;
    margin-bottom: 15px;
    color: #555;
    line-height: 1.5;
}

.verifying {
    color: #333;
}

.success {
    color: #1890ff;
}

.failed {
    color: #f5222d;
}

.login-link {
    color: #1890ff;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
}

.login-link:hover {
    text-decoration: underline;
    color: #40a9ff;
}

.re-verification-button {
    padding: 12px 24px;
    background-color: #1890ff;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
    margin-top: 10px;
    font-weight: 500;
}

.re-verification-button:hover {
    background-color: #40a9ff;
}

.spinner {
    border: 4px solid rgba(24, 144, 255, 0.3);
    border-left-color: #1890ff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 480px) {
    .verification-message {
        padding: 30px;
    }

    .verification-message h2 {
        font-size: 24px;
    }

    .verification-message p {
        font-size: 14px;
    }

    .re-verification-button {
        padding: 10px 20px;
        font-size: 14px;
    }
}