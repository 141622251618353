.table-container {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
}

.table-responsive {
    width: 100%;
    overflow-x: auto;
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-size: 14px;
    color: #333;
    font-family: 'IBM Plex Sans Condensed', sans-serif;
}

.table th,
.table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

.table th {
    background-color: #f5f5f5;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #555;
    border-top: 1px solid #e0e0e0;
}

.table tr:last-child td {
    border-bottom: none;
}

.table tbody tr:hover {
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
}

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
}

.pagination-controls {
    display: flex;
    align-items: center;
}

.pagination-controls button {
    margin: 0 5px;
    padding: 8px 12px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination-controls button:hover:not(:disabled) {
    background-color: #e0e0e0;
}

.pagination-controls button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.page-info {
    margin: 0 10px;
    font-size: 14px;
}

.page-size-select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    .table {
        font-size: 12px;
    }

    .table th,
    .table td {
        padding: 10px 8px;
    }

    .pagination {
        flex-direction: column;
        align-items: center;
    }

    .pagination-controls {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 480px) {
    .table {
        font-size: 11px;
    }

    .table th,
    .table td {
        padding: 8px 4px;
    }

    .pagination-controls button {
        padding: 6px 8px;
    }
}