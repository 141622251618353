.password-reset-container {
    display: flex;
    justify-content: center;
    align-items: start;
    min-height: 100vh;
    background: linear-gradient(to bottom, #e3f2fd, #bbdefb);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.password-reset-form {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 30px;
    border-radius: 10px;
    width: 400px;
    margin-top: 100px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.password-reset-form h2 {
    margin-bottom: 30px;
    text-align: center;
    color: #007bff;
    font-size: 24px;
}

.password-reset-form .form-label {
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
}

.password-reset-form .form-control {
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 16px;
}

.password-input {
    position: relative;
    margin-bottom: 20px;
}

.password-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
}

.password-toggle svg {
    width: 20px;
    height: 20px;
    fill: #6c757d;
    transition: fill 0.3s ease;
}

.password-toggle:hover svg {
    fill: #333;
}

.form-row {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.form-row button {
    width: 48%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-secondary {
    background-color: #6c757d;
    color: white;
    border: none;
}

.btn-secondary:hover {
    background-color: #5a6268;
}

.btn-primary {
    background-color: #007bff;
    color: white;
    border: none;
}

.btn-primary:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.alert {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
}

.alert-danger {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}