.career-compass-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    background: linear-gradient(to bottom, #e3f2fd, #bbdefb);
    padding: 40px 20px;
    font-family: 'Arial', sans-serif;
}

.header-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 40px;
    gap: 20px;
}

.header-content {
    flex: 1;
    text-align: left;
    padding-right: 40px;
}

.header-content h1 {
    font-size: 3.5em;
    margin-bottom: 20px;
    color: #007bff;
}

.header-content p {
    font-size: 1.3em;
    line-height: 1.6;
    color: #333;
}

.button-container {
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    flex-shrink: 0;
}

.career-compass-button {
    padding: 12px 20px;
    font-size: 1.2em;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    white-space: nowrap;
}

.career-compass-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.content-wrapper {
    width: 100%;
    max-width: 1200px;
}

.features-technologies {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.9);
    color: #333;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 25px;
    text-align: left;
}

.card h2 {
    color: #007bff;
    margin-bottom: 20px;
    font-size: 1.8em;
}

.card ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 1.1em;
}

.card ul li {
    margin-bottom: 15px;
    position: relative;
    padding-left: 25px;
}

.card ul li::before {
    content: "•";
    color: #007bff;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    position: absolute;
    left: 0;
}

.card-content {
    flex-grow: 1;
}

.pdf-button {
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-bottom: 5px;
    align-self: flex-start;
    width: 200px;
    text-align: center;
}

.pdf-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

@media (max-width: 1024px) {
    .header-section {
        flex-direction: column;
        align-items: center;
    }

    .header-content {
        text-align: center;
        padding-right: 0;
        margin-bottom: 20px;
    }

    .button-container {
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .header-content h1 {
        font-size: 2.5em;
    }

    .header-content p {
        font-size: 1.1em;
    }
}
