.add-tags-container {
    max-width: 500px;
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    margin-top: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-tags-background-container {
    display: flex;
    justify-content: center;
    align-items: start;
    min-height: 80vh;
    background: #90CCF4;
    /*background: linear-gradient(to bottom, #e3f2fd, #bbdefb);*/
    background-size: 200% 200%;
    animation: gradient 60s ease infinite;
}

.add-tags-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #007bff;
}

.tag-list {
    margin-bottom: 20px;
}

.tag {
    display: inline-block;
    background-color: #8aaf5d;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 3px;
    font-size: 14px;
    color: #333;
}

.input-container {
    display: flex;
    margin-bottom: 20px;
}

.tag-input {
    flex: 7;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 3px;
    margin-right: 10px;
}
.tag-input:focus{
    border-color: #007bff;
}
.add-tag-btn {
    flex: 3;
    padding: 8px 10px;
    font-size: 14px;
    background-color: #007bff;
    color: #fff;
    border: none;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
}
.add-tag-btn:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}
.confirm-btn {
    padding: 8px 18px;
    font-size: 14px;
    background-color: #007bff;
    color: #fff;
    border: none;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
}

.confirm-btn:hover {
    background-color: #0056b3;
    border-color: #6200ea;
    transform: translateY(-2px);
}

.added-tags-container {
    margin-bottom: 20px;
}

.added-tag-list {
    display: flex;
    flex-wrap: wrap;
}

.added-tag {
    display: inline-block;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 3px;
    font-size: 14px;
    color: #fff;
}

.remove-tag-btn {
    border: none;
    color: #da1818;
    margin-left: 5px;
    cursor: pointer;
    padding: 2px 5px;
}

.added-tag:nth-child(odd) {
    background-color: #007bff;
}

.added-tag:nth-child(even) {
    background-color: #28a745;
}

.added-tag:nth-child(3n) {
    background-color: #f0932b;
}

.added-tag:nth-child(4n) {
    background-color: #eb4d4b;
}
