.edit-form-container {
    max-width: 700px;
    width: 100%;
    margin: 50px auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #007bff;
    font-size: 24px;
}

.form-group {
    margin-bottom: 20px;
}

.form-row {
    display: flex;
    justify-content: space-between;
    /*margin-bottom: 20px;*/
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
    font-size: 16px;
}
.form-star {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.form-star label {
    display: block;
    margin-right: 10px;
    font-weight: bold;
    color: #333;
    font-size: 16px;
}

.form-group input[type='text'],
.form-group input[type='date'],
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 6px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.form-group input[type='text']:focus,
.form-group input[type='date']:focus,
.form-group select:focus,
.form-group textarea:focus {
    border-color: #007bff;
    outline: none;
}

.date-picker {
    width: 100%;
}

button {
    flex: 1;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn-primary {
    border-color: #7c4dff;
    background-color: #007bff;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s;
}

.btn-primary:hover {
    background-color: #007bff;
    border-color: #6200ea;
    transform: translateY(-2px);
}

.btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s;
}

.btn-secondary:hover {
    background-color: #5a6268;
    border-color: #545b62;
    transform: translateY(-2px);
}

.date-label {
    margin-top: 8px;
    margin-right: 10px;
    margin-bottom: 8px;
    font-size: 16px;
    color: #333;
}

.action-star {
    color: #ffd700;
    font-size: 20px;
    cursor: pointer;
}


.date-picker-wrapper {
    position: relative;
}

.rdp {
    position: absolute;
    z-index: 1000;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
}

.rdp-months {
    padding: 16px;
}

.rdp-month {
    background: white;
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
    background-color: #007bff;
    color: white;
}

.rdp-day:hover:not(.rdp-day_selected) {
    background-color: #f0e6ff;
}

.rdp-head_cell {
    font-weight: 600;
    color: #666;
}

.rdp-nav_button:hover {
    background-color: #f0e6ff;
}

.date-display {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 6px;
    font-size: 16px;
    transition: all 0.3s;
    cursor: pointer;
    background-color: white;
}

.date-display:hover {
    border-color: #007bff;
}

.calendar-icon {
    margin-right: 10px;
    color: #7c4dff;
}